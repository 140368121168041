// import Regions from "wavesurfer.js/dist/plugins/regions.esm.js";
import WaveSurfer from "wavesurfer.js";
import marked from "marked";
// import samplesFn from "./samples";
// const samples = samplesFn();
import samples from "./data/data.json";

/*

https://css-tricks.com/lots-of-ways-to-use-math-random-in-javascript/
https://grainy-gradients.vercel.app/
https://codepen.io/chrisgannon/post/how-to-make-an-svg-lava-lamp
*/

try {
  console.log({ samples });

  let isPlaying = false;
  let current = samples[0].audio;
  let loaded = false;
  let clicked = false;


  const title = document.querySelector("#title");
  const sticky = document.querySelector("#sticky");

  //add scroll spy intersection observer for when the title is out of view
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("intersecting");
          sticky.style.display = "none";
        } else {
          console.log("not intersecting");
          sticky.style.display = "block";
        }
      });
    },
    { threshold: 0.3 }
  );
  observer.observe(title);

  const play = document.querySelectorAll(".play");
  const pause = document.querySelectorAll(".pause");

  const maxHeight = window.innerHeight;

  const wavesurfer = WaveSurfer.create({
    container: "#waveform",
    waveColor: "#665200", //"#1e40af", //"#DA3633",
    progressColor: "hsl(48, 100%, 40%)", //"#60a5fa", //"#DA363399",
    url: `./data/${samples[0].audio}`,
    height: window.innerHeight * 0.2,
    dragToSeek: true,
    responsive: true,
    barWidth: null,
    barRadius: 3,
    barWidth: null,
    barRadius: 2,
    barGap: 5,
    splitChannels: false,
    // width: 500,
    autoScroll: true,
    interact: true,
    audioRate: 0.9,
    // playbackRate: 0.5,

    // renderFunction: (channels, ctx) => {
    //   const { width, height } = ctx.canvas
    //   const scale = channels[0].length / width
    //   const step = 8
  
    //   ctx.translate(0, height / 2)
    //   ctx.strokeStyle = ctx.fillStyle
    //   ctx.beginPath()
  
    //   for (let i = 0; i < width; i += step * 2) {
    //     const index = Math.floor(i * scale)
    //     const value = Math.abs(channels[0][index])
    //     let x = i
    //     let y = value * height
  
    //     ctx.moveTo(x, 0)
    //     ctx.lineTo(x, y)
    //     ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
    //     ctx.lineTo(x + step, 0)
  
    //     x = x + step
    //     y = -y
    //     ctx.moveTo(x, 0)
    //     ctx.lineTo(x, y)
    //     ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
    //     ctx.lineTo(x + step, 0)
    //   }
  
    //   ctx.stroke()
    //   ctx.closePath()
    // },
  });

  wavesurfer.on("load", () => {
    loaded = false;
  });

//   wavesurfer.on('ready', function () {
//     // Apply fade-in effect
//     wavesurfer.backend.gainNode.gain.setValueAtTime(0, 0);
//     wavesurfer.backend.gainNode.gain.linearRampToValueAtTime(1, 100); // Adjust the time duration as needed

//     // Apply fade-out effect
//     var duration = wavesurfer.getDuration();
//     wavesurfer.backend.gainNode.gain.setValueAtTime(1, duration - 100); // Adjust the time duration as needed
//     wavesurfer.backend.gainNode.gain.linearRampToValueAtTime(0, duration);
// });

  wavesurfer.on("loading", (e) => {
    if (e === 100) {
      loaded = true;
      console.log("loaded");
    }
  });

  wavesurfer.on("finish", function () {
    // wavesurfer.play();
    isPlaying = false;
    play.forEach(e => e.classList.add("active"));
    pause.forEach(e => e.classList.remove("active"));
  });

  wavesurfer.on("decode", function (e) {
    console.log(e);
  });

  // document.body.addEventListener("keypress", (e) => {
  //   e.preventDefault(); 
  //   console.log(e.code, e.key);
  //   if (e.code === "Space") playAudio();
  // });

  const playAudio = () => {
    console.log({ loaded, clicked, isPlaying, current });
    if (!loaded) {
      return setTimeout(() => playAudio(), 20);
    }
    const textEl = document.querySelector("#text");

    if (isPlaying) {
      play.forEach(e => e.classList.add("active"));
      pause.forEach(e => e.classList.remove("active"));
      wavesurfer.pause();
      isPlaying = false;
      textEl.style.display = "none";
    } else {
      clicked = true;
      play.forEach(e => e.classList.remove("active"));
      pause.forEach(e => e.classList.add("active"));
      wavesurfer.play();
      wavesurfer.setPlaybackRate(0.9);
      isPlaying = true;

      try {
        fetch(`./data/${current.replace("mp3", "txt")}`)
          .then((res) => res.text())
          .then((text) => {
            text.replace(/\n/g, "<br>");
            textEl.children[0].innerHTML = marked.parse(text);
            textEl.style.display = "block";
          });
      } catch (error) {
        console.log(error);
      }

    }
  };

  // app.addEventListener("mousemove", playAudio);
  // app.addEventListener("touchmove", playAudio);

  // app.addEventListener("click", () => {
  //   console.log("click");
  //   playAudio();
  // });

  const playButtons = document.querySelectorAll(".playbtn");
  playButtons.forEach((btn) => {
    btn.addEventListener("click", playAudio);
  });

  const toggle = document.querySelector(".samples");

  samples.forEach((sample, i) => {
    const btn = document.createElement("button");
    if (!i) btn.classList.add("active");

    btn.innerText = sample.name;

    btn.addEventListener("click", () => {
      const buttons = document.querySelectorAll(".samples button");
      buttons.forEach((e) => e.classList.remove("active"));
      btn.classList.add("active");

      if (current !== sample.audio) {
        current = sample.audio;
        isPlaying = false;
        wavesurfer.empty();
        wavesurfer.load(`./data/${sample.audio}`);
      }
      playAudio();
    });

    toggle.appendChild(btn);
  });
} catch (error) {
  console.log(error);
}
